import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Head from "next/head"

const Page = ({ title = "Pebble", link, meta, children, preloadAr }) => {
  const [curPage, setCurPage] = useState()
  useEffect(() => {
    const pageStr = window.location.pathname + window.location.search
    if (curPage !== pageStr && typeof window !== "undefined" && window.gtag) {
      window.gtag("config", "G-5J5H72FFJB", {
        page_title: document.title,
        page_path: pageStr,
      })
      setCurPage(pageStr)
    }
  }, [curPage])
  return (
    <>
      <Head>
        <title>{`${title} | Pebble Climbing`}</title>
        <meta property="og:type" content="website" key="website" />
        <meta name="viewport" content="width=device-width" />
        <meta property="twitter:card" content="summary" key="summary" />
        <meta property="twitter:title" content={title} key="twittitle" />
        <meta
          property="twitter:description"
          content={
            meta?.find((obj) => obj.name === "description")?.content ||
            "Pebble climbing"
          }
          key="twitdesc"
        />
        {meta &&
          meta?.map((met) => (
            <meta key={met.name || met.content || met.charset} {...met} />
          ))}
        {link && link.map((lin) => <link key={lin.rel + lin.href} {...lin} />)}
        <link
          key="assetlink"
          rel="assetlinks.json file"
          href="/.well-known/assetlinks.json"
        />
        <link
          key="apple-assoc"
          rel="apple-app-site-association file"
          href="/.well-known/apple-app-site-association"
        />
        <link
          key="apple-touch"
          rel="apple-touch-icon"
          href="/images/favicon.png"
        />
        <link
          key="apple-touch"
          rel="apple-touch-icon"
          href="/images/favicon.png"
        />
        <link rel="shortcut icon" href="/images/favicon.png" />
        {!!preloadAr &&
          preloadAr.map((url) => <link key={url} rel="preload" href={url} />)}
      </Head>
      {children}
    </>
  )
}

Page.propTypes = {
  title: PropTypes.string,
  link: PropTypes.array,
  meta: PropTypes.array,
}

export default Page
